import React from "react";
import ourVision from "../../assests/images/about/ourVision.png";
import ourMission from "../../assests/images/about/ourMission.png";
import SectionHeader from "../../UI/SectionHeader";
import overlay from "../../assests/images/overlay1.png";
const OurVision = () => {
  return (
    <section className="our-vision">
      <img src={overlay} alt="" className="overlay" />
      <div className="container">
        {/* <SectionHeader header={""} /> */}
        <div className="ourCards   flex-wrap">
          <div className="ourCard mb-4 col-lg-5 col-10">
            <img src={ourVision} alt="ourVision" />
            <h1>Our Vision</h1>
            <p>
              Our vision is to redefine interior and exterior environments
              through innovative wall covering materials. We aspire to be the
              leading choice for enhancing the aesthetic appeal and
              functionality of spaces in Canada
            </p>
          </div>
          <div className="ourCard col-lg-5 mb-4  col-10">
            <img src={ourMission} alt="ourMission" />
            <h1>Our Mission </h1>
            <p>
              Our vision is to redefine interior and exterior environments
              through innovative wall covering materials. We aspire to be the
              leading choice for enhancing the aesthetic appeal and
              functionality of spaces in Canada
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurVision;
