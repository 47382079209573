import React from "react";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import PageHeaderSection from "../UI/PageHeaderSection";
import PDFViewer from "pdf-viewer-reactjs";

export default function Catalog() {
  return (
    <div className="world-wide">
      <NavBar />
      <PageHeaderSection header={"Phomi Catalog"} />
      <div className="container">
        <PDFViewer
          document={{
            url: "https://arxiv.org/pdf/quant-ph/0410100.pdf",
          }}
        />
      </div>
      <Footer />
    </div>
  );
}
