import React from "react";
import { exteriorPhotos } from "../../assests/db/gallery";
import SectionHeader from "../../UI/SectionHeader";
const ExteriorPhotos = () => {
  return (
    <div className="container">
      <div className="mt-5 mb-5">
        <SectionHeader header={"EXTERIOR PHOTOS"} />
      </div>
      <div className="row">
        {exteriorPhotos.map((photo, index) => {
          return (
            <div className="grid-item col-lg-4 col-md-6 col-12 ">
              <img src={photo} alt="interiorImg" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ExteriorPhotos;
