import React from "react";
import SectionHeader from "../../UI/SectionHeader";
import overlay from "../../assests/images/about-overlay.png";
import art1 from "../../assests/images/art1.png";
import art2 from "../../assests/images/art2.png";
import art3 from "../../assests/images/art3.png";
import ArticalCard from "../../UI/ArticalCrad";
const Panels = (props) => {
  const data = [
    {
      img: art1,
      header: "PHOMI MCM PANELS WITH HEAT ",
      content:
        "MCM products are made from revolutionary patented natural clay-based composite material without toxic additives or hazardous chemicals. The raw materials we use in our MCM panels are commonly red, yellow, and white mud, crushed stone, sand, fly ash, cement waste, slag, and other inorganic substances.",
    },
    {
      img: art2,
      header: "PHOMI MCM PANELS",
      content:
        "MCM products are made from revolutionary patented natural clay-based composite material without toxic additives or hazardous chemicals. The raw materials we use in our MCM panels are commonly red, yellow, and white mud, crushed stone, sand, fly ash, cement waste, slag, and other inorganic substances.",
    },
    {
      img: art3,
      header: "IDR 200.000.000",
      content:
        "MCM products are made from revolutionary patented natural clay-based composite material without toxic additives or hazardous chemicals. The raw materials we use in our MCM panels are commonly red, yellow, and white mud, crushed stone, sand, fly ash, cement waste, slag, and other inorganic substances.",
    },
  ];
  return (
    <section className="panels-section py-5 ">
      <img src={overlay} alt="" className="overlay" />
      <div className="container">
        <SectionHeader header={"PHOMI MCM PANELS"} />
        <div className="row justify-content-between my-5">
          {data?.map((e, i) => {
            return <ArticalCard key={i} index={i} {...e} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default Panels;
