import React from "react";
import SectionHeader from "../../UI/SectionHeader";
import show1 from "../../assests/images/show/show1.jpg";
import show2 from "../../assests/images/show/show2.jpg";
import show3 from "../../assests/images/show/show3.jpg";
import show4 from "../../assests/images/show/show4.jpg";

const Examle = () => {
  return (
    <div className="container">
      <div className="my-4 my-md-5">
        <SectionHeader header={"Coverings"} />
      </div>
      <div className="example-gallery">
        <div className="example-item ">
          <img src={show1} alt="interiorImg" />
        </div>
        <div className="example-item ">
          <img src={show2} alt="interiorImg" />
        </div>
      </div>
      <div className="example-gallery flex-column my-3">
        <hr />
        <SectionHeader
          header={"LOOKING FORWARD TO SEEING THERE - VISIT OUR BOOTH"}
        />
        <div className="example-item col-lg-4 col-md-5 col-8 mt-3">
          <img src={show4} className="mh-100" alt="" />
        </div>
        <a
          target="_blank"
          href="https://canadianconcreteexpo.com/"
          className="d-block example-item mt-3"
        >
          <img src={show3} alt="interiorImg" />
        </a>
      </div>
    </div>
  );
};

export default Examle;
