import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../assests/images/logo.png";
import TopHeader from "./TopHeader";
const NavBar = (props) => {
  return (
    <header className="navbar-holder ">
      <TopHeader />
      <Navbar expand="lg" className=" py-3 px-0">
        <Container className="justify-content-between">
          <Navbar.Brand href="/">
            <img src={logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="">
              <Nav.Link href="/">HOME</Nav.Link>
              <Nav.Link href="/Gallery">GALLERY</Nav.Link>
              <Nav.Link href="/Products">PRODUCTS & CATALOG</Nav.Link>
              <Nav.Link href="/phomi-worldwide">PHOMI WORLDWIDE</Nav.Link>
              <Nav.Link href="/IntallationGuide">INSTALLATION GUIDE</Nav.Link>
              <Nav.Link href="/events"> SHOWS & EVENTS</Nav.Link>
              <Nav.Link href="/certificates"> CERTIFICATES </Nav.Link>

              <Nav.Link href="/About-us">ABOUT US</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default NavBar;
