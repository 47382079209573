import React from "react";
import flexible from "../../assests/images/about/felxible.png";
import weather from "../../assests/images/about/weather.png";
import durable from "../../assests/images/about/durable.png";
import water from "../../assests/images/about/water.svg";
import feather from "../../assests/images/about/feather.svg";
import fire from "../../assests/images/about/fire.svg";
import SectionHeader from "../../UI/SectionHeader";

const Characteristics = () => {
  return (
    <section className="characteristics container my-5">
      <SectionHeader header={"PHOMI CHARACTERISTICS"} />
      {/* <h1 className='chart-head'>PHOMI CHARACTERISTICS</h1> */}
      <div className="chart-items">
        <div className="chart-item">
          <img src={flexible} alt="" className="chart-icon" />
          <h2 className="chart-title">Flexible</h2>
        </div>
        <div className="chart-item">
          <img src={weather} alt="" className="chart-icon" />
          <h2 className="chart-title">Freeze Resistant</h2>
        </div>
        <div className="chart-item">
          <img src={durable} alt="" className="chart-icon" />
          <h2 className="chart-title">Durable</h2>
        </div>
        <div className="chart-item">
          <img src={water} alt="" className="chart-icon" />
          <h2 className="chart-title">Water Resistant</h2>
        </div>
        <div className="chart-item">
          <img src={fire} alt="" className="chart-icon" />
          <h2 className="chart-title">Fire Resistant</h2>
        </div>
        <div className="chart-item">
          <img src={feather} alt="" className="chart-icon" />
          <h2 className="chart-title">Light & Thin</h2>
        </div>
      </div>
    </section>
  );
};

export default Characteristics;
