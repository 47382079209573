import React from "react";
import classes from "./SectionHeader.module.css";
const SectionHeader = ({ header, subHeader }) => {
  return (
    <div className={classes.holder}>
      <h1 className={classes.header}>{header}</h1>
      {subHeader && <h2 className={classes.header}>{subHeader}</h2>}
      <div className={classes.line}></div>
    </div>
  );
};

export default SectionHeader;
