import React, { useState } from "react";

import ProductCard from "../../UI/ProductCard";
import { products } from "../../assests/db/products";
import productCategories from "../../assests/db/newProducts";
import { act } from "react";

const ProductTabs = () => {
  function getImageName(imagePath) {
    // Extracts the full image name with extension
    return imagePath.split("/").pop();
  }

  function getImageNameWithoutExtension(imagePath) {
    // Extracts the image name without the extension
    const fullName = getImageName(imagePath); // Get the last part after '/'
    return fullName.substring(0, fullName.lastIndexOf(".")) || fullName;
  }
  const [activeTab, setActiveTab] = useState("all");

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  console.log(activeTab);
  return (
    <div className="container">
      <div className="tab-buttons flex-wrap">
        <button
          onClick={() => handleTabClick("all")}
          className={activeTab === "all" ? "active" : ""}
        >
          All
        </button>

        {productCategories?.map((category, i) => (
          <button
            onClick={() => handleTabClick(category.mainCategory)}
            className={activeTab === category.mainCategory ? "active" : ""}
          >
            {category.mainCategory}
          </button>
        ))}
      </div>

      <div className="tab-content">
        <div className="row my-5">
          {productCategories
            .filter(
              (category) =>
                activeTab === "all" || category.mainCategory === activeTab
            )
            .map((product, i) => {
              return product.subCategories?.map((subCategory, j) => {
                return subCategory?.images?.map((image, k) => (
                  <ProductCard
                    key={`${i}-${j}-${k}`}
                    name={subCategory.name}
                    img={`https://phomi.ca/${image}`}
                    header={getImageNameWithoutExtension(image)}
                  />
                ));
              });
            })}
        </div>
      </div>
    </div>
  );
};

export default ProductTabs;
