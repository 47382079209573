import React from "react";

const Country = (props) => {
  return (
    <a href={props.href} className="country-holder col-lg-4 col-6">
      <div className="img-holder">
        <img src={props.img} alt="" />
      </div>
      <p className="name text-white">{props.name}</p>
    </a>
  );
};

export default Country;
