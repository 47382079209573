import React from "react";

const ArticalCard = (props) => {
  return (
    <div
      data-aos="fade-up"
      data-aos-delay={(props.index + 1) * 200}
      className="col-lg-4 col-md-6 col-12"
    >
      <div className="artical-card">
        <div className="img-holder">
          <img src={props.img} alt="" />
        </div>
        <h1 className="header">{props.header}</h1>
        <p className="content">{props.content}</p>
      </div>
    </div>
  );
};

export default ArticalCard;
