import React, { useRef, useState } from "react";

import emailjs from "@emailjs/browser";

const RadioButton = ({ id, label, onClick }) => (
  <div className="radio-button">
    <input
      name="radio-group"
      id={id}
      className="radio-button__input"
      type="radio"
      onClick={() => {
        onClick();
      }}
    />
    <label htmlFor={id} className="radio-button__label">
      <span className="radio-button__custom"></span>
      {label}
    </label>
  </div>
);
export default function ContactUs() {
  const [subject, setSubject] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_d88tokj", "template_vufoakw", form.current, {
        publicKey: "Hjk13ie8-sy8doo5r",
      })
      .then(
        () => {
          alert("Your Message has been received thank you ❤️");
          window.location.reload();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  return (
    <section className="py-5 contact-us">
      <div className="container d-flex justify-content-md-center align-items-center flex-wrap">
        <div className="col-lg-7 col-12 contact-form">
          <form ref={form} onSubmit={sendEmail} action="">
            <input
              className="d-none"
              name="subject"
              id="subject"
              value={subject}
              type="text"
            />
            <div className="input-holder">
              <input required={true} name="name" id="name" type="text" />
              <label htmlFor="name">Your Name</label>
            </div>

            <div className="input-holder">
              <input
                required={true}
                name="company_name"
                id="company-name"
                type="text"
              />
              <label htmlFor="company-name">Company Name</label>
            </div>
            <div className="input-holder">
              <input required={true} name="email" id="email" type="email" />
              <label htmlFor="email">Email</label>
            </div>
            <div className="input-holder">
              <input
                required={true}
                name="province"
                id="province"
                type="text"
              />
              <label htmlFor="province">Province</label>
            </div>
            <div className="input-holder">
              <input required={true} name="city" id="city" type="text" />
              <label htmlFor="city">City</label>
            </div>
            <div className="distributor  mb-5">
              <p style={{ color: "rgb(231,231,231)" }}>Why this Message for</p>
              <div className=" d-flex flex-wrap gap-4">
                <RadioButton
                  onClick={() => {
                    setSubject("Feedback");
                    console.log(form.current);
                  }}
                  id="radio1"
                  label="Feedback"
                />
                <RadioButton
                  onClick={() => {
                    setSubject("I'm Distributor");
                    console.log(form.current);
                  }}
                  id="radio2"
                  label="I'm Distributor"
                />
                <RadioButton
                  onClick={() => {
                    setSubject("Requesting Distribution");
                    console.log(form.current);
                  }}
                  id="radio3"
                  label="Requesting Distribution"
                />
              </div>
            </div>
            <div className="input-holder">
              <textarea
                required={true}
                rows={4}
                name="message"
                id="message"
                type="text"
              />
              <label htmlFor="message">Message</label>
            </div>
            <div className="view-more">
              <button>send</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
