import React from "react";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
export default function PDFViewer({ url, state, setState }) {
  return (
    <div
      style={{ visibility: state ? "visible" : "hidden" }}
      className="pdf-holder"
    >
      <div
        onClick={() => {
          setState(!state);
        }}
        className="close"
      >
        X
      </div>
      <iframe
        src={url}
        style={{ width: "90vw", height: "90vh" }}
        frameborder="0"
      ></iframe>
    </div>
  );
}
