import React from "react";
import SectionHeader from "../../UI/SectionHeader";
import before from "../../assests/images/gallery/before.jpg";
import after from "../../assests/images/gallery/after.jpg";

const Examle = () => {
  return (
    <div className="container">
      <div className="mt-5 mb-5">
        <SectionHeader header={"SOME SHOWCASE"} />
      </div>
      <div className="example-gallery">
        <div className="example-item ">
          <img src={before} alt="interiorImg" />
          <h3 className="text-white">Before</h3>
        </div>
        <div className="example-item ">
          <img src={after} alt="interiorImg" />
          <h3 className="text-white">After</h3>
        </div>
      </div>
    </div>
  );
};

export default Examle;
