import React from "react";
import SectionHeader from "../../UI/SectionHeader";
import show1 from "../../assests/images/certificates/American Standard Fire Resistance Test Certificate_page-0001.jpg";
import show2 from "../../assests/images/certificates/USA Standard Fire Rating Certificate_page-0001.jpg";
import reportImg1 from "../../assests/images/certificates/MCM Industry Standard Test Report (Water, Freeze, Durability, etc.)_page-0001.jpg";
import reportImg2 from "../../assests/images/certificates/MCM Industry Standard Test Report (Water, Freeze, Durability, etc.)_page-0002.jpg";
import reportImg3 from "../../assests/images/certificates/MCM Industry Standard Test Report (Water, Freeze, Durability, etc.)_page-0003.jpg";
import reportImg4 from "../../assests/images/certificates/MCM Industry Standard Test Report (Water, Freeze, Durability, etc.)_page-0004.jpg";
import reportImg5 from "../../assests/images/certificates/MCM Industry Standard Test Report (Water, Freeze, Durability, etc.)_page-0005.jpg";
import patentImg1 from "../../assests/images/certificates/USA Patent (1).jpg";
import patentImg2 from "../../assests/images/certificates/USA Patent (2).jpg";
import ULImg from "../../assests/images/certificates/UL Certification.jpg";

const CertificatesHolder = () => {
  return (
    <div className="container">
      <div className="my-4 my-md-5">
        <SectionHeader
          header={"American Standard Fire Resistance Test Certificate"}
        />
      </div>
      <div className="example-gallery">
        <div className="p-2 border border-1 border-white col-md-8 col-10">
          <img src={show1} alt="interiorImg" />
        </div>
      </div>
      <div className="example-gallery flex-column my-3">
        <hr />
        <SectionHeader header={"USA Standard Fire Rating Certificate"} />
        <div className="p-2 border border-1 border-white col-md-8 col-10 mt-3">
          <img src={show2} className="mh-100" alt="" />
        </div>
      </div>
      <div className="example-gallery flex-column my-3">
        <hr />
        <SectionHeader
          header={"MCM Industry Standard Test Report"}
          subHeader={"(Water, Freeze, Durability, etc.)"}
        />
        <div className="p-2 border border-1 border-white col-md-8 col-10 mt-3">
          <img src={reportImg1} className="mh-100" alt="" />
        </div>
        <div className="p-2 border border-1 border-white col-md-8 col-10 mt-3">
          <img src={reportImg2} className="mh-100" alt="" />
        </div>
        <div className="p-2 border border-1 border-white col-md-8 col-10 mt-3">
          <img src={reportImg3} className="mh-100" alt="" />
        </div>
        <div className="p-2 border border-1 border-white col-md-8 col-10 mt-3">
          <img src={reportImg4} className="mh-100" alt="" />
        </div>
        <div className="p-2 border border-1 border-white col-md-8 col-10 mt-3">
          <img src={reportImg5} className="mh-100" alt="" />
        </div>
      </div>
      <div className="example-gallery flex-column my-3">
        <hr />
        <SectionHeader header={"USA Patent"} />
        <div className="p-2 border border-1 border-white col-md-8 col-10 mt-3">
          <img src={patentImg1} className="mh-100" alt="" />
        </div>
        <div className="p-2 border border-1 border-white col-md-8 col-10 mt-3">
          <img src={patentImg2} className="mh-100" alt="" />
        </div>
      </div>
      <div className="example-gallery flex-column my-3">
        <hr />
        <SectionHeader header={"UL Certification"} />
        <div className="p-2 border border-1 border-white col-md-8 col-10 mt-3">
          <img src={ULImg} className="mh-100" alt="" />
        </div>
      </div>
    </div>
  );
};

export default CertificatesHolder;
