import React from 'react';
import SectionHeader from '../../UI/SectionHeader';
import ProductCard from '../../UI/ProductCard';

const OurStrategic = () => {
  const data = [
    {
      header: 'Product Diversification',
      info: [
        'Our vision is to redefine interior and exterior environments through innovative wall covering materials. We aspire to be the leading choice for enhancing the aesthetic appeal and functionality of spaces in Canada',
      ],
    },
    {
      header: 'Sustainable Practices',
      info: [
        'Implement and enhance sustainable manufacturing processes, sourcing eco-friendly materials, and minimizing environmental impact throughout the product lifecycle.',
      ],
    },
    {
      header: 'Operational Efficiency',
      info: [
        ' Optimize internal processes to enhance efficiency, reduce costs, and streamline supply chain management, ensuring a seamless and agile operation',
      ],
    },
    {
      header: 'Brand Recognition',
      info: [
        'Build a strong and distinctive brand image through effective marketing strategies, emphasizing the values of quality, sustainability, and design excellence',
      ],
    },
    {
      header: 'Customer-Centric Approach',
      info: [
        'Strengthen customer relationships by providing exceptional service, personalized solutions, and responsive support, leading to high customer satisfaction and loyalty',
      ],
    },
    {
      header: 'Talent Development',
      info: [
        ' Foster a culture of continuous learning, innovation, and collaboration, attracting and retaining top industry talent to drive the company',
      ],
    },
  ];
  return (
    <section className='our-products  py-5'>
      <div className='container'>
        <SectionHeader header={'Our Strategic Objectives'} />
        <p className='text-center title'>
          The aim of our strategic objectives is to position the company as a
          leader in the wall covering material industry, known for its
          commitment to innovation, sustainability, and customer satisfaction.
        </p>
        <div className='row my-5'>
          {data?.map((e, i) => {
            return <ProductCard noImg={true} key={i} {...e} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default OurStrategic;
