import React from "react";
import aboutImg from "../../assests/images/about.png";

const AboutHeader = (props) => {
  return (
    <section className="about-us py-5 my-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <img style={{ width: "100%" }} src={aboutImg} alt="" />
          </div>
          <div className="text-content col-lg-6">
            <h1> Phomi Canada</h1>
            <p className="text-white">
              Phomi Canada, founded in 2024, is a limited liability company
              formed to collaborate with Phomi China, the pioneering
              manufacturer of environmentally-friendly products designed for
              interior walls, exteriors, ceilings, and flooring. Recognizing the
              significance of this groundbreaking innovation, Phomi Canada
              aligns seamlessly with Canada's strong commitment to environmental
              conservation and sustainable practices. Our dedication goes beyond
              business – it's a pledge to actively contribute to environmental
              betterment. We believe it is our responsibility, and we embrace
              the opportunity to champion eco-friendly solutions for the benefit
              of our planet.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHeader;
