import React from "react";
import bg from "../../assests/images/building1.jpg";
import bg2 from "../../assests/images/building2.jpg";
import bg3 from "../../assests/images/building3.jpg";
import bg4 from "../../assests/images/building4.jpg";
import Carousel from "react-bootstrap/Carousel";

const HeroSection = (props) => {
  return (
    <section className="hero-section ">
      <Carousel>
        <Carousel.Item interval={30000}>
          <div className="carousel-slide orange d-flex flex-wrap">
            {" "}
            <div className="text-content mx-auto col-lg-6 col-12">
              <h1>Phomi MCM Panels</h1>
              <p>Material Science That Shapes the Future</p>
            </div>
            <div className="img-holder">
              <img src={bg} alt="" />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={1200}>
          <div className="carousel-slide gray d-flex flex-wrap">
            {" "}
            <div className="text-content mx-auto col-lg-6 col-12">
              <h1>Phomi MCM Panels</h1>
              <p>
                PHOMI , the pioneer of patented technology in Modified Clay
                Materials is the industry leader in the world for eco-friendly
                building materials
              </p>
            </div>
            <div className="img-holder">
              <img src={bg2} alt="" />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={1200}>
          <div className="carousel-slide blue d-flex flex-wrap">
            {" "}
            <div className="text-content mx-auto col-lg-6 col-12">
              <h1>Phomi MCM Panels</h1>
              <p>
                Our products are lighter, Durable,Easier to install , Cost and
                Time efficient, A-Grade Fire Retardant and Green Product
                Certified{" "}
              </p>
            </div>
            <div className="img-holder">
              <img src={bg3} alt="" />
            </div>
          </div>
        </Carousel.Item>{" "}
        <Carousel.Item interval={1200}>
          <div className="carousel-slide beige d-flex flex-wrap">
            {" "}
            <div className="text-content mx-auto col-lg-6 col-12">
              <h1>Phomi MCM Panels</h1>
              <p>
                PHOMI's products can be installed for Outdoor and Indoor usage.
                Almost every kind of Walls,FLoors,Ceiling, Column and Pillars
              </p>
            </div>
            <div className="img-holder">
              <img src={bg4} alt="" />
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
      {/* <div className="container d-flex justify-content-between align-items-end">
        <div className="text-content col-lg-6">
          <h1>Phomi MCM Panels</h1>
          <p>Material Science That Shapes the Future</p>
        </div>
        <div className="img-holder col-lg-5">
          <img style={{ width: "110%" }} src={bg} alt="" />
        </div>
      </div>
      <div className="support-word">support word</div> */}
    </section>
  );
};

export default HeroSection;
