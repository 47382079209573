// productCategories.js

const productCategories = [
  {
    mainCategory: "Travertine",
    subCategories: [
      {
        name: "Greece Travertine",
        images: [
          "PHOMI 2024/01 Greece Travertine/Anna Light Grey.jpg",
          "PHOMI 2024/01 Greece Travertine/Anna Medium Grey.jpg",
          "PHOMI 2024/01 Greece Travertine/Anna White.jpg",
          "PHOMI 2024/01 Greece Travertine/Kamu Grey.jpg",
          "PHOMI 2024/01 Greece Travertine/Kamu Red.jpg",
          "PHOMI 2024/01 Greece Travertine/Kamu Yellow.jpg",
        ],
      },
      {
        name: "Coral Travertine",
        images: [
          "PHOMI 2024/02 Coral Travertine/Cloud Yellow.jpg",
          "PHOMI 2024/02 Coral Travertine/Stellar Grey.jpg",
          "PHOMI 2024/02 Coral Travertine/Stellar Red.jpg",
          "PHOMI 2024/02 Coral Travertine/Stellar White.jpg",
          "PHOMI 2024/02 Coral Travertine/Stellar Yellow.jpg",
          "PHOMI 2024/02 Coral Travertine/Stratus Brown.jpg",
          "PHOMI 2024/02 Coral Travertine/Stratus Dark Grey.jpg",
          "PHOMI 2024/02 Coral Travertine/Stratus Light Grey.jpg",
        ],
      },
      {
        name: "Rome Travertine",
        images: [
          "PHOMI 2024/03 Rome Travertine/Andes Gold.jpg",
          "PHOMI 2024/03 Rome Travertine/Andes Grey.jpg",
          "PHOMI 2024/03 Rome Travertine/Andes Light Brown.jpg",
          "PHOMI 2024/03 Rome Travertine/Andes Red.jpg",
          "PHOMI 2024/03 Rome Travertine/Andes White.jpg",
          "PHOMI 2024/03 Rome Travertine/Andes Yellow.jpg",
          "PHOMI 2024/03 Rome Travertine/Cloud Grey.jpg",
          "PHOMI 2024/03 Rome Travertine/Cloud Orange.jpg",
          "PHOMI 2024/03 Rome Travertine/Cloud White.jpg",
          "PHOMI 2024/03 Rome Travertine/Cloud Yellow.jpg",
        ],
      },
      {
        name: "Cloud Silk Travertine",
        images: [
          "PHOMI 2024/04 Cloud Silk Travertine/Ash Grey.jpg",
          "PHOMI 2024/04 Cloud Silk Travertine/Black Sesame.jpg",
          "PHOMI 2024/04 Cloud Silk Travertine/Kamu Red.jpg",
          "PHOMI 2024/04 Cloud Silk Travertine/Kamu Yellow.jpg",
          "PHOMI 2024/04 Cloud Silk Travertine/Multi-Color Blue.jpg",
          "PHOMI 2024/04 Cloud Silk Travertine/Multi-Color Rust.jpg",
          "PHOMI 2024/04 Cloud Silk Travertine/Pency.jpg",
          "PHOMI 2024/04 Cloud Silk Travertine/Snow Flack Red.jpg",
        ],
      },
      {
        name: "Oceanic Travertine",
        images: [
          "PHOMI 2024/05 Oceanic Travertine/Greek Yellow-1.jpg",
          "PHOMI 2024/05 Oceanic Travertine/Greek Yellow-2.jpg",
          "PHOMI 2024/05 Oceanic Travertine/Ink Cloud Grey-1.jpg",
          "PHOMI 2024/05 Oceanic Travertine/Ink Cloud Grey-2.jpg",
          "PHOMI 2024/05 Oceanic Travertine/Light Cloud Grey-1.jpg",
          "PHOMI 2024/05 Oceanic Travertine/Light Cloud Grey-2.jpg",
          "PHOMI 2024/05 Oceanic Travertine/Moonlight White-1.jpg",
          "PHOMI 2024/05 Oceanic Travertine/Moonlight White-2.jpg",
        ],
      },
      {
        name: "Rought Surface",
        images: [
          "PHOMI 2024/06 Rought Surface/Andes Gold.jpg",
          "PHOMI 2024/06 Rought Surface/Ash Grey.jpg",
          "PHOMI 2024/06 Rought Surface/Bro Grey.jpg",
          "PHOMI 2024/06 Rought Surface/Castol Blue.jpg",
          "PHOMI 2024/06 Rought Surface/Castol Grey.jpg",
          "PHOMI 2024/06 Rought Surface/Castol White.jpg",
          "PHOMI 2024/06 Rought Surface/Castol Yellow.jpg",
          "PHOMI 2024/06 Rought Surface/Gold Sesame.jpg",
          "PHOMI 2024/06 Rought Surface/Greyish Brown.jpg",
          "PHOMI 2024/06 Rought Surface/Loki Mountain Medium Grey.jpg",
          "PHOMI 2024/06 Rought Surface/Multi-Color Red.jpg",
          "PHOMI 2024/06 Rought Surface/Nile Dark Grey.jpg",
          "PHOMI 2024/06 Rought Surface/Pearl Blue.jpg",
          "PHOMI 2024/06 Rought Surface/Portoro.jpg",
          "PHOMI 2024/06 Rought Surface/Rock Mottle Brown.jpg",
          "PHOMI 2024/06 Rought Surface/Romam Red.jpg",
          "PHOMI 2024/06 Rought Surface/Roman Grey.jpg",
          "PHOMI 2024/06 Rought Surface/Sahara Noir.jpg",
          "PHOMI 2024/06 Rought Surface/Starry.jpg",
          "PHOMI 2024/06 Rought Surface/Tunguska Yellow.jpg",
          "PHOMI 2024/06 Rought Surface/Veil Grey.jpg",
          "PHOMI 2024/06 Rought Surface/White Sesame.jpg",
        ],
      },
    ],
  },
  {
    mainCategory: "Marble",
    subCategories: [
      {
        name: "Marble",
        images: [
          "PHOMI 2024/07 Marble/Andes Gold.jpg",
          "PHOMI 2024/07 Marble/Andes Grey.jpg",
          "PHOMI 2024/07 Marble/Andes Light Brown.jpg",
          "PHOMI 2024/07 Marble/Andes Red.jpg",
          "PHOMI 2024/07 Marble/Andes White.jpg",
          "PHOMI 2024/07 Marble/Andes Yellow.jpg",
          "PHOMI 2024/07 Marble/Castol Blue.jpg",
          "PHOMI 2024/07 Marble/Castol Grey.jpg",
          "PHOMI 2024/07 Marble/Castol White.jpg",
          "PHOMI 2024/07 Marble/Cloud Grey.jpg",
          "PHOMI 2024/07 Marble/Cloud Orange.jpg",
          "PHOMI 2024/07 Marble/Cloud White.jpg",
          "PHOMI 2024/07 Marble/Cloud Yellow.jpg",
          "PHOMI 2024/07 Marble/Loki Mountain Light Grey.jpg",
          "PHOMI 2024/07 Marble/Nile Dark Grey.jpg",
          "PHOMI 2024/07 Marble/Nile Dark Red.jpg",
          "PHOMI 2024/07 Marble/Nile Warm Grey.jpg",
          "PHOMI 2024/07 Marble/Sahara Light Grey.jpg",
          "PHOMI 2024/07 Marble/Sahara Noir.jpg",
          "PHOMI 2024/07 Marble/Salento Yellow.jpg",
          "PHOMI 2024/07 Marble/Starry.jpg",
          "PHOMI 2024/07 Marble/Veil Grey.jpg",
          "PHOMI 2024/07 Marble/Veil White.jpg",
          "PHOMI 2024/07 Marble/Veil Yellow.jpg",
        ],
      },
    ],
  },
  {
    mainCategory: "Polished Stone",
    subCategories: [
      {
        name: "Polished Stone",
        images: [
          "PHOMI 2024/08 Polished Stone/Andes White.jpg",
          "PHOMI 2024/08 Polished Stone/Andes Yellow.jpg",
          "PHOMI 2024/08 Polished Stone/Castol White.jpg",
          "PHOMI 2024/08 Polished Stone/Henessy Dark Grey.jpg",
          "PHOMI 2024/08 Polished Stone/Henessy Light Grey.jpg",
          "PHOMI 2024/08 Polished Stone/Salento Yellow.jpg",
          "PHOMI 2024/08 Polished Stone/Spanish Beige.jpg",
          "PHOMI 2024/08 Polished Stone/Sunis White.jpg",
          "PHOMI 2024/08 Polished Stone/Veil Grey.jpg",
          "PHOMI 2024/08 Polished Stone/Veil White.jpg",
        ],
      },
      {
        name: "Rusty Slab",
        images: [
          "PHOMI 2024/10 Rusty Slab/Copper.jpg",
          "PHOMI 2024/10 Rusty Slab/Fantasy Red.jpg",
          "PHOMI 2024/10 Rusty Slab/Fawn Grey.jpg",
          "PHOMI 2024/10 Rusty Slab/Gilt.jpg",
          "PHOMI 2024/10 Rusty Slab/Maca Blue.jpg",
          "PHOMI 2024/10 Rusty Slab/Nuée Ardente.jpg",
        ],
      },
      {
        name: "Granite Flat",
        images: [
          "PHOMI 2024/11 Granite Flat/Ash Grey.jpg",
          "PHOMI 2024/11 Granite Flat/Beauty.jpg",
          "PHOMI 2024/11 Granite Flat/Black Sesame.jpg",
          "PHOMI 2024/11 Granite Flat/Gold Sesame.jpg",
          "PHOMI 2024/11 Granite Flat/Greyish Brown.jpg",
          "PHOMI 2024/11 Granite Flat/Multi-Color Red.jpg",
          "PHOMI 2024/11 Granite Flat/New White Sesame.jpg",
          "PHOMI 2024/11 Granite Flat/Pearl Blue.jpg",
          "PHOMI 2024/11 Granite Flat/Snow Flack Red.jpg",
          "PHOMI 2024/11 Granite Flat/White Sesame.jpg",
        ],
      },
      {
        name: "Mount Celestial",
        images: [
          "PHOMI 2024/12 Mount Celestial/Castol Blue.jpg",
          "PHOMI 2024/12 Mount Celestial/Castol Grey.jpg",
          "PHOMI 2024/12 Mount Celestial/Castol White.jpg",
          "PHOMI 2024/12 Mount Celestial/Loki Mountain Light Grey.jpg",
          "PHOMI 2024/12 Mount Celestial/Loki Mountain Medium Grey.jpg",
          "PHOMI 2024/12 Mount Celestial/Mount Lion Yellow.jpg",
          "PHOMI 2024/12 Mount Celestial/Portoro.jpg",
          "PHOMI 2024/12 Mount Celestial/Tunguska Yellow.jpg",
          "PHOMI 2024/12 Mount Celestial/Veil Dark Grey.jpg",
          "PHOMI 2024/12 Mount Celestial/Veil Grey.jpg",
          "PHOMI 2024/12 Mount Celestial/Veil White.jpg",
          "PHOMI 2024/12 Mount Celestial/Veil Yellow.jpg",
        ],
      },
      {
        name: "Skyline",
        images: [
          "PHOMI 2024/13 Skyline/H01-02-03.jpg",
          "PHOMI 2024/13 Skyline/Nile Cool Grey.jpg",
          "PHOMI 2024/13 Skyline/Nile Dark Grey.jpg",
          "PHOMI 2024/13 Skyline/Nile Dark Red.jpg",
          "PHOMI 2024/13 Skyline/Nile Light Yellow.jpg",
          "PHOMI 2024/13 Skyline/Nile Medium Yellow.jpg",
          "PHOMI 2024/13 Skyline/Nile Warm Grey.jpg",
          "PHOMI 2024/13 Skyline/Veil Grey.jpg",
          "PHOMI 2024/13 Skyline/Veil White.jpg",
          "PHOMI 2024/13 Skyline/Y001-01-02.jpg",
        ],
      },
      {
        name: "Sandstone",
        images: [
          "PHOMI 2024/14 Sandstone/Australia Orange.jpg",
          "PHOMI 2024/14 Sandstone/Australia Yellow.jpg",
          "PHOMI 2024/14 Sandstone/H02砂岩.jpg",
          "PHOMI 2024/14 Sandstone/Kaman White.jpg",
          "PHOMI 2024/14 Sandstone/Kaman Yellow.jpg",
          "PHOMI 2024/14 Sandstone/Plain White.jpg",
        ],
      },
      {
        name: "Rammed Earth Wall",
        images: [
          "PHOMI 2024/15 Rammed Earth Wall/Bataan Yellow.jpg",
          "PHOMI 2024/15 Rammed Earth Wall/Brownish Red.jpg",
          "PHOMI 2024/15 Rammed Earth Wall/Bulin Red.jpg",
          "PHOMI 2024/15 Rammed Earth Wall/Dublin Grey.jpg",
          "PHOMI 2024/15 Rammed Earth Wall/Egyptian Orange.jpg",
          "PHOMI 2024/15 Rammed Earth Wall/Egyptian Yellow.jpg",
          "PHOMI 2024/15 Rammed Earth Wall/Light Grey.jpg",
          "PHOMI 2024/15 Rammed Earth Wall/Medium Grey.jpg",
          "PHOMI 2024/15 Rammed Earth Wall/Pop Yellow.jpg",
          "PHOMI 2024/15 Rammed Earth Wall/Red.jpg",
          "PHOMI 2024/15 Rammed Earth Wall/Yellow Grey.jpg",
          "PHOMI 2024/15 Rammed Earth Wall/Yellow.jpg",
        ],
      },
    ],
  },

  {
    mainCategory: "Stone",
    subCategories: [
      {
        name: "Crossard Mushroom Stone",
        images: [
          "PHOMI 2024/16 Crossard Mushroom Stone/Blue Grey.jpg",
          "PHOMI 2024/16 Crossard Mushroom Stone/Castol Yellow.jpg",
          "PHOMI 2024/16 Crossard Mushroom Stone/Cloud White.jpg",
          "PHOMI 2024/16 Crossard Mushroom Stone/Multi-Color Blue.jpg",
          "PHOMI 2024/16 Crossard Mushroom Stone/Multi-Color Rust.jpg",
          "PHOMI 2024/16 Crossard Mushroom Stone/Nile Dark Grey.jpg",
          "PHOMI 2024/16 Crossard Mushroom Stone/Plain White.jpg",
          "PHOMI 2024/16 Crossard Mushroom Stone/Portoro.jpg",
          "PHOMI 2024/16 Crossard Mushroom Stone/Tunguska Yellow.jpg",
        ],
      },
      {
        name: "Devine Mushroom Stone",
        images: [
          "PHOMI 2024/17 Devine Mushroom Stone/Andes Grey.jpg",
          "PHOMI 2024/17 Devine Mushroom Stone/Andes White.jpg",
          "PHOMI 2024/17 Devine Mushroom Stone/Andes Yellow.jpg",
          "PHOMI 2024/17 Devine Mushroom Stone/Blue Grey.jpg",
          "PHOMI 2024/17 Devine Mushroom Stone/Gold Sesame.jpg",
          "PHOMI 2024/17 Devine Mushroom Stone/Loki Mountain Medium Grey.jpg",
          "PHOMI 2024/17 Devine Mushroom Stone/Multi-Color Rust.jpg",
          "PHOMI 2024/17 Devine Mushroom Stone/Nile Dark Grey.jpg",
          "PHOMI 2024/17 Devine Mushroom Stone/Nile Dark Red.jpg",
          "PHOMI 2024/17 Devine Mushroom Stone/Portoro.jpg",
          "PHOMI 2024/17 Devine Mushroom Stone/Tunguska Yellow.jpg",
        ],
      },
      {
        name: "Oblique Mushroom Stone",
        images: [
          "PHOMI 2024/18 Oblique Mushroom Stone/Anna White.jpg",
          "PHOMI 2024/18 Oblique Mushroom Stone/Loki Mountain Medium Grey.jpg",
          "PHOMI 2024/18 Oblique Mushroom Stone/Nile Dark Grey.jpg",
          "PHOMI 2024/18 Oblique Mushroom Stone/Nile Light Yellow.jpg",
          "PHOMI 2024/18 Oblique Mushroom Stone/Plain White.jpg",
          "PHOMI 2024/18 Oblique Mushroom Stone/Spanish Light Grey.jpg",
          "PHOMI 2024/18 Oblique Mushroom Stone/Tunguska Yellow.jpg",
        ],
      },
      {
        name: "Concrete Pouring Slab",
        images: [
          "PHOMI 2024/19 Concrete Pouring Slab/Castle Rock Grey.jpg",
          "PHOMI 2024/19 Concrete Pouring Slab/Ink-Dyed.jpg",
          "PHOMI 2024/19 Concrete Pouring Slab/Light Grey.jpg",
          "PHOMI 2024/19 Concrete Pouring Slab/Mist.jpg",
          "PHOMI 2024/19 Concrete Pouring Slab/Plain White.jpg",
          "PHOMI 2024/19 Concrete Pouring Slab/Sandstorm.jpg",
        ],
      },
      {
        name: "Arcute Rock",
        images: [
          "PHOMI 2024/20 Arcute Rock/Andes Yellow.jpg",
          "PHOMI 2024/20 Arcute Rock/Autumn Yellow.jpg",
          "PHOMI 2024/20 Arcute Rock/Gold Sesame.jpg",
          "PHOMI 2024/20 Arcute Rock/Medium Grey.jpg",
          "PHOMI 2024/20 Arcute Rock/Thin CLouds Grey.jpg",
          "PHOMI 2024/20 Arcute Rock/Veil White.jpg",
        ],
      },
      {
        name: "Infiniti",
        images: [
          "PHOMI 2024/21 Infiniti/Andes Yellow.jpg",
          "PHOMI 2024/21 Infiniti/Ash Grey.jpg",
          "PHOMI 2024/21 Infiniti/Dandy Grey.jpg",
          "PHOMI 2024/21 Infiniti/Greyish Brown.jpg",
          "PHOMI 2024/21 Infiniti/HG03.jpg",
          "PHOMI 2024/21 Infiniti/Multi-Color Red.jpg",
          "PHOMI 2024/21 Infiniti/Nile Dark Red.jpg",
          "PHOMI 2024/21 Infiniti/Plain White.jpg",
          "PHOMI 2024/21 Infiniti/Spanish Light Yellow.jpg",
          "PHOMI 2024/21 Infiniti/Veil White.jpg",
        ],
      },
      {
        name: "piece Stone",
        images: [
          "PHOMI 2024/22 35 piece Stone/Andes Gold.jpg",
          "PHOMI 2024/22 35 piece Stone/Ash Grey.jpg",
          "PHOMI 2024/22 35 piece Stone/Dandy Grey.jpg",
          "PHOMI 2024/22 35 piece Stone/Loki Mountain Light Grey.jpg",
          "PHOMI 2024/22 35 piece Stone/Multi-Color Red.jpg",
          "PHOMI 2024/22 35 piece Stone/Multi-Color Rust.jpg",
          "PHOMI 2024/22 35 piece Stone/Nile Dark Grey.jpg",
          "PHOMI 2024/22 35 piece Stone/Tunguska Yellow.jpg",
        ],
      },
      {
        name: "Chiseled Stone",
        images: [
          "PHOMI 2024/23 Chiseled Stone/Andes White.jpg",
          "PHOMI 2024/23 Chiseled Stone/Andes Yellow.jpg",
          "PHOMI 2024/23 Chiseled Stone/Ash Grey.jpg",
          "PHOMI 2024/23 Chiseled Stone/Cloud White.jpg",
          "PHOMI 2024/23 Chiseled Stone/Dandy Grey.jpg",
          "PHOMI 2024/23 Chiseled Stone/Gold Sesame.jpg",
          "PHOMI 2024/23 Chiseled Stone/Greyish Brown.jpg",
          "PHOMI 2024/23 Chiseled Stone/Multi-Color Red.jpg",
          "PHOMI 2024/23 Chiseled Stone/Multi-Color Rust.jpg",
          "PHOMI 2024/23 Chiseled Stone/Plain White.jpg",
        ],
      },
      {
        name: "Oman Linear Stone",
        images: [
          "PHOMI 2024/24 Oman Linear Stone/Andes Red.jpg",
          "PHOMI 2024/24 Oman Linear Stone/Andes White.jpg",
          "PHOMI 2024/24 Oman Linear Stone/Andes Yellow.jpg",
          "PHOMI 2024/24 Oman Linear Stone/Ash Grey.jpg",
          "PHOMI 2024/24 Oman Linear Stone/Black Sesame.jpg",
          "PHOMI 2024/24 Oman Linear Stone/Dandy Grey.jpg",
          "PHOMI 2024/24 Oman Linear Stone/Gold Sesame.jpg",
          "PHOMI 2024/24 Oman Linear Stone/Loki Mountain Medium Grey.jpg",
          "PHOMI 2024/24 Oman Linear Stone/Multi-Color Red.jpg",
          "PHOMI 2024/24 Oman Linear Stone/Nile Medium Yellow.jpg",
          "PHOMI 2024/24 Oman Linear Stone/Veil Grey.jpg",
          "PHOMI 2024/24 Oman Linear Stone/White Sesame.jpg",
        ],
      },
      {
        name: "Stone Ridged",
        images: [
          "PHOMI 2024/25 Stone Ridged/Andes White.jpg",
          "PHOMI 2024/25 Stone Ridged/Andes Yellow.jpg",
          "PHOMI 2024/25 Stone Ridged/Ash Grey.jpg",
          "PHOMI 2024/25 Stone Ridged/Cloud White.jpg",
          "PHOMI 2024/25 Stone Ridged/Dandy Grey.jpg",
          "PHOMI 2024/25 Stone Ridged/Gold Sesame.jpg",
          "PHOMI 2024/25 Stone Ridged/Greyish Brown.jpg",
          "PHOMI 2024/25 Stone Ridged/Loki Mountain Medium Grey.jpg",
          "PHOMI 2024/25 Stone Ridged/Multi-Color Red.jpg",
          "PHOMI 2024/25 Stone Ridged/Multi-Color Rust.jpg",
          "PHOMI 2024/25 Stone Ridged/Nile Medium Yellow.jpg",
          "PHOMI 2024/25 Stone Ridged/White Sesame.jpg",
        ],
      },
      {
        name: "Bush Hammered",
        images: [
          "PHOMI 2024/26 Bush Hammered/Ash Grey.jpg",
          "PHOMI 2024/26 Bush Hammered/Beauty.jpg",
          "PHOMI 2024/26 Bush Hammered/Black Sesame.jpg",
          "PHOMI 2024/26 Bush Hammered/Dandy Grey.jpg",
          "PHOMI 2024/26 Bush Hammered/Gold Sesame.jpg",
          "PHOMI 2024/26 Bush Hammered/Magnolia.jpg",
          "PHOMI 2024/26 Bush Hammered/Multi-Color Red.jpg",
          "PHOMI 2024/26 Bush Hammered/Pale Yellow.jpg",
          "PHOMI 2024/26 Bush Hammered/Starry.jpg",
          "PHOMI 2024/26 Bush Hammered/Washed-out White.jpg",
        ],
      },
      {
        name: "Butterfly Stone",
        images: [
          "PHOMI 2024/27 Butterfly Stone/BN02.jpg",
          "PHOMI 2024/27 Butterfly Stone/FN04.jpg",
          "PHOMI 2024/27 Butterfly Stone/HN02.jpg",
          "PHOMI 2024/27 Butterfly Stone/ON02.jpg",
          "PHOMI 2024/27 Butterfly Stone/Plain White.jpg",
          "PHOMI 2024/27 Butterfly Stone/RN03.jpg",
          "PHOMI 2024/27 Butterfly Stone/Spanish Beige.jpg",
          "PHOMI 2024/27 Butterfly Stone/WN01.jpg",
        ],
      },
      {
        name: "3D Papel",
        images: [
          "PHOMI 2024/28 3D Papel/Andes White.jpg",
          "PHOMI 2024/28 3D Papel/Ash Grey.jpg",
          "PHOMI 2024/28 3D Papel/HB04.jpg",
          "PHOMI 2024/28 3D Papel/Loki Mountain Light Grey.jpg",
          "PHOMI 2024/28 3D Papel/Multi-Color Rust.jpg",
          "PHOMI 2024/28 3D Papel/Nile Cool Grey.jpg",
          "PHOMI 2024/28 3D Papel/Nile Dark Red.jpg",
          "PHOMI 2024/28 3D Papel/Nile Light Yellow.jpg",
        ],
      },
      {
        name: "Stackle Square",
        images: [
          "PHOMI 2024/29 Stackle Square/Andes Yellow.jpg",
          "PHOMI 2024/29 Stackle Square/Ash Grey.jpg",
          "PHOMI 2024/29 Stackle Square/Gold Sesame.jpg",
          "PHOMI 2024/29 Stackle Square/Loki Mountain Medium Grey.jpg",
          "PHOMI 2024/29 Stackle Square/Multi-Color Rust.jpg",
          "PHOMI 2024/29 Stackle Square/Tunguska Yellow.jpg",
        ],
      },
      {
        name: "Rockface Stone",
        images: [
          "PHOMI 2024/30 Rockface Stone/Andes White.jpg",
          "PHOMI 2024/30 Rockface Stone/Ash Grey.jpg",
          "PHOMI 2024/30 Rockface Stone/Beauty.jpg",
          "PHOMI 2024/30 Rockface Stone/Bro Grey.jpg",
          "PHOMI 2024/30 Rockface Stone/Multi-Color Blue.jpg",
          "PHOMI 2024/30 Rockface Stone/Multi-Color Rust.jpg",
          "PHOMI 2024/30 Rockface Stone/Nile  Red.jpg",
          "PHOMI 2024/30 Rockface Stone/Portoro.jpg",
          "PHOMI 2024/30 Rockface Stone/Stellar Grey.jpg",
          "PHOMI 2024/30 Rockface Stone/Tunguska Yellow.jpg",
        ],
      },
    ],
  },
  {
    mainCategory: "Wood",
    subCategories: [
      {
        name: "Spliced Wood",
        images: [
          "PHOMI 2024/31 Spliced Wood/Cangshan Mountain Grey.jpg",
          "PHOMI 2024/31 Spliced Wood/Former Time.jpg",
          "PHOMI 2024/31 Spliced Wood/Plain White.jpg",
          "PHOMI 2024/31 Spliced Wood/Sand Yellow.jpg",
          "PHOMI 2024/31 Spliced Wood/Silk-mist Brown.jpg",
          "PHOMI 2024/31 Spliced Wood/Twilight Mountain Grey.jpg",
        ],
      },
      {
        name: "Poly Wood",
        images: [
          "PHOMI 2024/32 Poly Wood/Autumn.jpg",
          "PHOMI 2024/32 Poly Wood/Brownish Yellow.jpg",
          "PHOMI 2024/32 Poly Wood/Caesious.jpg",
          "PHOMI 2024/32 Poly Wood/Dark Brown.jpg",
          "PHOMI 2024/32 Poly Wood/Light Brown.jpg",
          "PHOMI 2024/32 Poly Wood/Moonlight White.jpg",
          "PHOMI 2024/32 Poly Wood/Orange.jpg",
          "PHOMI 2024/32 Poly Wood/Peach.jpg",
          "PHOMI 2024/32 Poly Wood/Plain Brown.jpg",
          "PHOMI 2024/32 Poly Wood/Sunglow.jpg",
          "PHOMI 2024/32 Poly Wood/Sunset Yellow.jpg",
          "PHOMI 2024/32 Poly Wood/Yoly.jpg",
        ],
      },
      {
        name: "Original Wood",
        images: [
          "PHOMI 2024/33 Original Wood/Autumn.jpg",
          "PHOMI 2024/33 Original Wood/Brownish Yellow.jpg",
          "PHOMI 2024/33 Original Wood/Caesious.jpg",
          "PHOMI 2024/33 Original Wood/Dark Brown.jpg",
          "PHOMI 2024/33 Original Wood/Layered Rock.jpg",
          "PHOMI 2024/33 Original Wood/Light Brown.jpg",
          "PHOMI 2024/33 Original Wood/Moonlight White.jpg",
          "PHOMI 2024/33 Original Wood/Peach.jpg",
          "PHOMI 2024/33 Original Wood/Plain Brown.jpg",
          "PHOMI 2024/33 Original Wood/Sunglow.jpg",
          "PHOMI 2024/33 Original Wood/Sunset Yellow.jpg",
          "PHOMI 2024/33 Original Wood/Yoly.jpg",
        ],
      },
      {
        name: "Sawtooth Wood",
        images: [
          "PHOMI 2024/34 Sawtooth Wood/Autumn.jpg",
          "PHOMI 2024/34 Sawtooth Wood/Brownish Yellow.jpg",
          "PHOMI 2024/34 Sawtooth Wood/Caesious.jpg",
          "PHOMI 2024/34 Sawtooth Wood/Dark Brown.jpg",
          "PHOMI 2024/34 Sawtooth Wood/Light Brown.jpg",
          "PHOMI 2024/34 Sawtooth Wood/Moonlight White.jpg",
          "PHOMI 2024/34 Sawtooth Wood/Orange.jpg",
          "PHOMI 2024/34 Sawtooth Wood/Peach.jpg",
          "PHOMI 2024/34 Sawtooth Wood/Plain Brown.jpg",
          "PHOMI 2024/34 Sawtooth Wood/Sunglow.jpg",
          "PHOMI 2024/34 Sawtooth Wood/Sunset Yellow.jpg",
          "PHOMI 2024/34 Sawtooth Wood/Yoly.jpg",
        ],
      },
      {
        name: " Polished Wood",
        images: [
          "PHOMI 2024/35 Polished Wood/Dark Brown.jpg",
          "PHOMI 2024/35 Polished Wood/Perth Grey.jpg",
          "PHOMI 2024/35 Polished Wood/Perth White.jpg",
          "PHOMI 2024/35 Polished Wood/Robe Red.jpg",
          "PHOMI 2024/35 Polished Wood/Steinburg Yellow.jpg",
          "PHOMI 2024/35 Polished Wood/Sunset Yellow.jpg",
        ],
      },
    ],
  },
  {
    mainCategory: "Weaving",
    subCategories: [
      {
        name: "W Weaving",
        images: [
          "PHOMI 2024/36 W Weaving/BN02.jpg",
          "PHOMI 2024/36 W Weaving/FN04.jpg",
          "PHOMI 2024/36 W Weaving/HN02.jpg",
          "PHOMI 2024/36 W Weaving/O01.jpg",
          "PHOMI 2024/36 W Weaving/RN03.jpg",
          "PHOMI 2024/36 W Weaving/WN01.jpg",
        ],
      },
      {
        name: "Ocean Flower Weaving",
        images: [
          "PHOMI 2024/37 Ocean Flower Weaving/BN02.jpg",
          "PHOMI 2024/37 Ocean Flower Weaving/Castol Blue.jpg",
          "PHOMI 2024/37 Ocean Flower Weaving/FN04.jpg",
          "PHOMI 2024/37 Ocean Flower Weaving/HN02.jpg",
          "PHOMI 2024/37 Ocean Flower Weaving/ON02.jpg",
          "PHOMI 2024/37 Ocean Flower Weaving/RN03.jpg",
          "PHOMI 2024/37 Ocean Flower Weaving/Tunguska Yellow.jpg",
          "PHOMI 2024/37 Ocean Flower Weaving/WN01.jpg",
        ],
      },
      {
        name: "W Herringbone",
        images: [
          "PHOMI 2024/38 W Herringbone/BN02.jpg",
          "PHOMI 2024/38 W Herringbone/FN04.jpg",
          "PHOMI 2024/38 W Herringbone/HN02.jpg",
          "PHOMI 2024/38 W Herringbone/O03.jpg",
          "PHOMI 2024/38 W Herringbone/ON02.jpg",
          "PHOMI 2024/38 W Herringbone/WN01.jpg",
        ],
      },
      {
        name: "Rope Wave A",
        images: [
          "PHOMI 2024/39 Rope Wave A/BN02.jpg",
          "PHOMI 2024/39 Rope Wave A/FN04.jpg",
          "PHOMI 2024/39 Rope Wave A/HN02.jpg",
          "PHOMI 2024/39 Rope Wave A/HY001.jpg",
          "PHOMI 2024/39 Rope Wave A/ON02.jpg",
          "PHOMI 2024/39 Rope Wave A/RN03.jpg",
        ],
      },
      {
        name: "Rope Wave B",
        images: [
          "PHOMI 2024/40 Rope Wave B/BN02.jpg",
          "PHOMI 2024/40 Rope Wave B/FN04.jpg",
          "PHOMI 2024/40 Rope Wave B/HN02.jpg",
          "PHOMI 2024/40 Rope Wave B/HY001.jpg",
          "PHOMI 2024/40 Rope Wave B/ON02.jpg",
          "PHOMI 2024/40 Rope Wave B/RN03.jpg",
        ],
      },
    ],
  },
  {
    mainCategory: "Rattan",
    subCategories: [
      {
        name: "Rattan Mat A",
        images: [
          "PHOMI 2024/41 Rattan Mat A/BN02.jpg",
          "PHOMI 2024/41 Rattan Mat A/FN04.jpg",
          "PHOMI 2024/41 Rattan Mat A/HN02.jpg",
          "PHOMI 2024/41 Rattan Mat A/ON02.jpg",
          "PHOMI 2024/41 Rattan Mat A/WN01.jpg",
          "PHOMI 2024/41 Rattan Mat A/Y03.jpg",
        ],
      },
      {
        name: "Rattan Mat B",
        images: [
          "PHOMI 2024/42 Rattan Mat B/BN02.jpg",
          "PHOMI 2024/42 Rattan Mat B/FN04.jpg",
          "PHOMI 2024/42 Rattan Mat B/HN02.jpg",
          "PHOMI 2024/42 Rattan Mat B/HY001.jpg",
          "PHOMI 2024/42 Rattan Mat B/ON02.jpg",
          "PHOMI 2024/42 Rattan Mat B/RN03.jpg",
        ],
      },
      {
        name: "Bamboo Straw",
        images: [
          "PHOMI 2024/43 Bamboo Straw/BN02.jpg",
          "PHOMI 2024/43 Bamboo Straw/F04.jpg",
          "PHOMI 2024/43 Bamboo Straw/FN04.jpg",
          "PHOMI 2024/43 Bamboo Straw/HN02.jpg",
          "PHOMI 2024/43 Bamboo Straw/ON02.jpg",
          "PHOMI 2024/43 Bamboo Straw/WN01.jpg",
        ],
      },
      {
        name: "Rattan Knit A",
        images: [
          "PHOMI 2024/44 Rattan Knit A/BN02.jpg",
          "PHOMI 2024/44 Rattan Knit A/FN04.jpg",
          "PHOMI 2024/44 Rattan Knit A/HN02.jpg",
          "PHOMI 2024/44 Rattan Knit A/ON02.jpg",
          "PHOMI 2024/44 Rattan Knit A/WN01.jpg",
          "PHOMI 2024/44 Rattan Knit A/Y03.jpg",
        ],
      },
    ],
  },
  {
    mainCategory: "Brick",
    subCategories: [
      {
        name: "G Facing Brick",
        images: [
          "PHOMI 2024/45 G Facing Brick/British Blue.jpg",
          "PHOMI 2024/45 G Facing Brick/Idyllic Brown.jpg",
          "PHOMI 2024/45 G Facing Brick/Idyllic Medium Grey.jpg",
          "PHOMI 2024/45 G Facing Brick/Idyllic Orange.jpg",
          "PHOMI 2024/45 G Facing Brick/Idyllic White.jpg",
          "PHOMI 2024/45 G Facing Brick/Idyllic Yellow.jpg",
          "PHOMI 2024/45 G Facing Brick/Inks Cyan.jpg",
          "PHOMI 2024/45 G Facing Brick/Inks Dark Brown.jpg",
          "PHOMI 2024/45 G Facing Brick/Inks Grey.jpg",
          "PHOMI 2024/45 G Facing Brick/Kata Orange.jpg",
          "PHOMI 2024/45 G Facing Brick/Kata Silver.jpg",
          "PHOMI 2024/45 G Facing Brick/Kata White.jpg",
          "PHOMI 2024/45 G Facing Brick/Kata Yellow.jpg",
          "PHOMI 2024/45 G Facing Brick/Knight Cyan.jpg",
          "PHOMI 2024/45 G Facing Brick/Knight Red.jpg",
          "PHOMI 2024/45 G Facing Brick/Knigt Grey.jpg",
          "PHOMI 2024/45 G Facing Brick/Misted  Grey.JPG",
          "PHOMI 2024/45 G Facing Brick/Misted White.jpg",
          "PHOMI 2024/45 G Facing Brick/Nilo Brown.jpg",
          "PHOMI 2024/45 G Facing Brick/Os Cyan.jpg",
          "PHOMI 2024/45 G Facing Brick/Os Orange.jpg",
          "PHOMI 2024/45 G Facing Brick/Os Red.jpg",
          "PHOMI 2024/45 G Facing Brick/Palace Brown-Green.jpg",
          "PHOMI 2024/45 G Facing Brick/Palace Orange-Green.jpg",
          "PHOMI 2024/45 G Facing Brick/Snowy Brown.jpg",
          "PHOMI 2024/45 G Facing Brick/Snowy White.jpg",
          "PHOMI 2024/45 G Facing Brick/Time Grey.jpg",
          "PHOMI 2024/45 G Facing Brick/Time White.jpg",
        ],
      },
      {
        name: "Stone Brick",
        images: [
          "PHOMI 2024/46 Stone Brick/Anna Medium Grey.jpg",
          "PHOMI 2024/46 Stone Brick/Anna White.jpg",
          "PHOMI 2024/46 Stone Brick/Copper.jpg",
          "PHOMI 2024/46 Stone Brick/Fantasy Red.jpg",
          "PHOMI 2024/46 Stone Brick/Gilded Iron.jpg",
          "PHOMI 2024/46 Stone Brick/Gold Sesame.jpg",
          "PHOMI 2024/46 Stone Brick/Greyish Brown.jpg",
          "PHOMI 2024/46 Stone Brick/Kamu Grey.jpg",
          "PHOMI 2024/46 Stone Brick/Kamu Yellow.jpg",
          "PHOMI 2024/46 Stone Brick/Maca Blue.jpg",
          "PHOMI 2024/46 Stone Brick/Multi-Color Red.jpg",
          "PHOMI 2024/46 Stone Brick/Polish Concrete Wal Medium Grey.jpg",
          "PHOMI 2024/46 Stone Brick/Polish Concrete Wall Fog.jpg",
          "PHOMI 2024/46 Stone Brick/Portoro.jpg",
        ],
      },
    ],
  },
  ,
  {
    mainCategory: "Other",
    subCategories: [
      {
        name: "Polish Concrete Wall",
        images: [
          "PHOMI 2024/09 Polish Concrete Wall/Cloud.jpg",
          "PHOMI 2024/09 Polish Concrete Wall/Dark Brown.jpg",
          "PHOMI 2024/09 Polish Concrete Wall/Dark Grey.jpg",
          "PHOMI 2024/09 Polish Concrete Wall/Fog.jpg",
          "PHOMI 2024/09 Polish Concrete Wall/Great Desert.jpg",
          "PHOMI 2024/09 Polish Concrete Wall/Greyish Desert.jpg",
          "PHOMI 2024/09 Polish Concrete Wall/Ink-Dyed.jpg",
          "PHOMI 2024/09 Polish Concrete Wall/Medium Grey.jpg",
        ],
      },
    ],
  },
];

export default productCategories;
