import React from "react";
import NavBar from "../Components/NavBar";
import PageHeaderSection from "../UI/PageHeaderSection";
import InteriorPhotos from "../Components/Gallery/InteriorPhotos";
import ExteriorPhotos from "../Components/Gallery/ExteriorPhotos";
import Videos from "../Components/Gallery/Videos";
import Footer from "../Components/Footer";
import Examle from "../Components/Events/Examle";

const Events = () => {
  return (
    <div className="gallery">
      <NavBar />
      <PageHeaderSection header={"Shows & Events"} />
      <Examle />

      <Footer />
    </div>
  );
};

export default Events;
