import React from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import euFlag from "../assests/images/flags/eu.jpg";
import Country from "../UI/Country";
import PageHeaderSection from "../UI/PageHeaderSection";

const PhomiWorldwide = (props) => {
  const data = [
    {
      img: "https://www.worldometers.info/img/flags/us-flag.gif",
      name: "Phomi USA",
      href: "https://phomi-usa.com/",
    },
    {
      img: "https://www.worldometers.info/img/flags/rp-flag.gif",
      name: "Phomi Philippines",
      href: "https://www.phomi.ph/",
    },
    {
      img: euFlag,
      name: "Phomi Euorpe",
      href: "https://phomi.eu/",
    },
    {
      img: "https://www.worldometers.info/img/flags/id-flag.gif",
      name: "Phomi Indonesia",
      href: "https://phomimcm.com/",
    },
    {
      img: "https://www.worldometers.info/img/flags/ch-flag.gif",
      name: "Phomi China",
      href: "https://www.phomi-mcm.com/",
    },
    {
      img: "https://www.worldometers.info/img/flags/sa-flag.gif",
      name: "Phomi Saudi Arabia",
      href: "https://gbs.sa.com/",
    },
  ];
  return (
    <div className="world-wide">
      <NavBar />
      <PageHeaderSection header={"Phomi Worldwide"} />
      <div className="container">
        <div className="row justify-content-between  mt-5">
          {data.map((e, i) => {
            return <Country key={i} {...e} />;
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PhomiWorldwide;
