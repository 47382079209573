import React from "react";
import NavBar from "../Components/NavBar";
import PageHeaderSection from "../UI/PageHeaderSection";
import InteriorPhotos from "../Components/Gallery/InteriorPhotos";
import ExteriorPhotos from "../Components/Gallery/ExteriorPhotos";
import Examle from "../Components/Gallery/Examle";
import Videos from "../Components/Gallery/Videos";
import Footer from "../Components/Footer";

const Gallery = () => {
  return (
    <div className="gallery">
      <NavBar />
      <PageHeaderSection header={"Gallery"} />
      <Examle />
      <InteriorPhotos />
      
      <Videos />
      <ExteriorPhotos />
      <Footer/>
    </div>
  );
};

export default Gallery;
