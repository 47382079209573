import React from "react";
import NavBar from "../Components/NavBar";

import PageHeaderSection from "../UI/PageHeaderSection";
import ProductTabs from "../Components/Products/Products-tab";
import Characteristics from "../Components/aboutus/Characteristics";
import Footer from "../Components/Footer";
import Catalog from "../Components/Products/Catalog";

const Products = () => {
  return (
    <div className="products">
      <NavBar />

      <PageHeaderSection header={"Products"} />

      <Characteristics />
      <Catalog />
      <ProductTabs />
      <Footer />
    </div>
  );
};

export default Products;
