import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SectionHeader from "../../UI/SectionHeader";
import gallary1 from "../../assests/images/gallary1.png";
import gallary2 from "../../assests/images/gallary2.png";
import gallary3 from "../../assests/images/gallary3.png";
export default function OurProjects() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <section className="our-projects py-5 my-5">
      <SectionHeader header={"OUR PROJECTS"} />
      <div className="container my-5">
        <Carousel responsive={responsive}>
          <div className="carousel-holder">
            <img style={{ width: "100%" }} src={gallary1} alt="" />
          </div>
          <div className="carousel-holder">
            <img style={{ width: "100%" }} src={gallary2} alt="" />
          </div>
          <div className="carousel-holder">
            <img style={{ width: "100%" }} src={gallary3} alt="" />
          </div>
          <div className="carousel-holder">
            <img style={{ width: "100%" }} src={gallary1} alt="" />
          </div>
          <div className="carousel-holder">
            <img style={{ width: "100%" }} src={gallary2} alt="" />
          </div>
          <div className="carousel-holder">
            <img style={{ width: "100%" }} src={gallary3} alt="" />
          </div>
        </Carousel>
      </div>
    </section>
  );
}
