import React from 'react';
import { interiorPhotos } from '../../assests/db/gallery';
import SectionHeader from '../../UI/SectionHeader';
const InteriorPhotos = () => {
  return (
    <div className=''>
      <div className='mt-5 mb-5'>
        <SectionHeader header={'INTERIOR PHOTOS'} />
      </div>
      <div className='image-gallery'>
        {interiorPhotos.map((photo, index) => {
          return (
            <div className='gallery-item '>
              <img src={photo} alt='interiorImg' />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InteriorPhotos;
