import React from "react";
import NavBar from "../Components/NavBar";
import PageHeaderSection from "../UI/PageHeaderSection";
import { DefaultPlayer as Video } from "react-html5video";

import Footer from "../Components/Footer";

const IntalltionGuide = () => {
  return (
    <section className="intallationGuide">
      <NavBar />
      <PageHeaderSection header={"INSTALLATION TOOL GUIDE"} />
      <div className="d-flex my-5 justify-content-center">
        {" "}
        <div className="col-lg-6 mb-3 col-md-12 ">
          {" "}
          <Video
            controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
            onCanPlayThrough={() => {
              // Do stuff
            }}
          >
            <source src={"/videos/vid5.mp4"} type="video/mp4" />
            <track
              label="English"
              kind="subtitles"
              srcLang="en"
              src="http://source.vtt"
              default
            />
          </Video>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default IntalltionGuide;
