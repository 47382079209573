import React from 'react';
import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';

const PopularChoices = () => {
  return (
    <div className='home'>
      <NavBar />

      <Footer />
    </div>
  );
};

export default PopularChoices;
