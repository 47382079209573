import React from "react";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import SectionHeader from "../../UI/SectionHeader";

const Videos = ({ src }) => {
  return (
    <div className="container">
      <div className="mt-5 mb-5">
        <SectionHeader header={"Videos "} />
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-3 mb-3 col-md-4 col-10">
          {" "}
          <Video
            controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
            onCanPlayThrough={() => {
              // Do stuff
            }}
          >
            <source src={"/videos/vid1.mp4"} type="video/mp4" />
            <track
              label="English"
              kind="subtitles"
              srcLang="en"
              src="http://source.vtt"
              default
            />
          </Video>
        </div>
        <div className="col-lg-3 mb-3 col-md-4 col-10">
          {" "}
          <Video
            controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
            onCanPlayThrough={() => {
              // Do stuff
            }}
          >
            <source src={"/videos/vid2.mp4"} type="video/mp4" />
            <track
              label="English"
              kind="subtitles"
              srcLang="en"
              src="http://source.vtt"
              default
            />
          </Video>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-6 mb-3 col-md-12 ">
          {" "}
          <Video
            controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
            onCanPlayThrough={() => {
              // Do stuff
            }}
          >
            <source src={"/videos/vid3.mp4"} type="video/mp4" />
            <track
              label="English"
              kind="subtitles"
              srcLang="en"
              src="http://source.vtt"
              default
            />
          </Video>
        </div>
        <div className="col-lg-6 mb-3 col-md-12 ">
          {" "}
          <Video
            controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
            onCanPlayThrough={() => {
              // Do stuff
            }}
          >
            <source src={"/videos/vid4.mp4"} type="video/mp4" />
            <track
              label="English"
              kind="subtitles"
              srcLang="en"
              src="http://source.vtt"
              default
            />
          </Video>
        </div>
        <div className="col-lg-6 mb-3 col-md-12 ">
          {" "}
          <Video
            controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
            onCanPlayThrough={() => {
              // Do stuff
            }}
          >
            <source src={"/videos/vid5.mp4"} type="video/mp4" />
            <track
              label="English"
              kind="subtitles"
              srcLang="en"
              src="http://source.vtt"
              default
            />
          </Video>
        </div>
        <div className="col-lg-6 mb-3 col-md-12 ">
          {" "}
          <Video
            controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
            onCanPlayThrough={() => {
              // Do stuff
            }}
          >
            <source src={"/videos/vid6.mp4"} type="video/mp4" />
            <track
              label="English"
              kind="subtitles"
              srcLang="en"
              src="http://source.vtt"
              default
            />
          </Video>
        </div>
      </div>
    </div>
  );
};

export default Videos;
