// ALL interiorPhotos gallery
import inter1 from "../images/gallery/INTERIOR/interior1.jpeg";
import inter2 from "../images/gallery/INTERIOR/interior2.jpeg";
import inter3 from "../images/gallery/INTERIOR/interior3.jpeg";
import inter4 from "../images/gallery/INTERIOR/interior4.jpeg";
import inter5 from "../images/gallery/INTERIOR/interior5.jpeg";
import inter6 from "../images/gallery/INTERIOR/interior6.jpeg";
import inter8 from "../images/gallery/INTERIOR/interior8.jpeg";
import inter9 from "../images/gallery/INTERIOR/interior9.jpg";
import inter10 from "../images/gallery/INTERIOR/interior10.jpg";
import inter11 from "../images/gallery/INTERIOR/interior11.jpg";
import inter12 from "../images/gallery/INTERIOR/interior12.jpg";
import inter13 from "../images/gallery/INTERIOR/interior13.jpg";

// ALL exteriorPhotos gallery
import exter1 from "../images/gallery/EXTERIOR/exterior1.jpeg";
import exter2 from "../images/gallery/EXTERIOR/exterior2.jpeg";
import exter3 from "../images/gallery/EXTERIOR/exterior3.jpeg";
import exter4 from "../images/gallery/EXTERIOR/exterior4.jpeg";
import exter5 from "../images/gallery/EXTERIOR/exterior5.jpeg";
import exter6 from "../images/gallery/EXTERIOR/exterior6.jpeg";
import exter7 from "../images/gallery/EXTERIOR/exterior7.jpeg";
import exter8 from "../images/gallery/EXTERIOR/exterior8.jpeg";
import exter9 from "../images/gallery/EXTERIOR/exterior9.jpeg";
import exter10 from "../images/gallery/EXTERIOR/exterior10.jpeg";
import exter11 from "../images/gallery/EXTERIOR/exterior11.jpeg";
import exter12 from "../images/gallery/EXTERIOR/exterior12.jpeg";
import exter13 from "../images/gallery/EXTERIOR/exterior13.jpeg";
import exter14 from "../images/gallery/EXTERIOR/exterior14.jpeg";
import exter15 from "../images/gallery/EXTERIOR/exterior15.jpeg";
import exter16 from "../images/gallery/EXTERIOR/exterior16.jpeg";
import exter17 from "../images/gallery/EXTERIOR/exterior17.jpeg";
import exter18 from "../images/gallery/EXTERIOR/exterior18.jpeg";
import exter19 from "../images/gallery/EXTERIOR/exterior19.jpeg";
import exter20 from "../images/gallery/EXTERIOR/exterior20.jpeg";

export const interiorPhotos = [
  inter9,
  inter10,
  inter11,
  inter12,
  inter13,
  inter1,
  inter2,
  inter3,
  inter4,
  inter5,
  inter6,
  inter1,
  inter8,
];

export const exteriorPhotos = [
  exter1,
  exter2,
  exter3,
  exter4,
  exter5,
  exter6,
  exter7,
  exter8,
  exter9,
  exter10,
  exter11,
  exter12,
  exter13,
  exter14,
  exter15,
  exter16,
  exter17,
  exter18,
  exter19,
  exter20,
];
