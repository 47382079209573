import React from "react";

const WhatCard = (props) => {
  return (
    <div data-aos="fade-up" className="mb-4 col-lg-3 col-md-6 col-12">
      <div className="whatCard">
        <div className="img-holder">
          <img src={props.img} alt="" />
        </div>
        <h1 className="header">{props.header}</h1>
        <p className="content">{props.content}</p>
      </div>
    </div>
  );
};

export default WhatCard;
