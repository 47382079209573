import React from "react";
import NavBar from "../Components/NavBar";
import PageHeaderSection from "../UI/PageHeaderSection";
import AboutHeader from "../Components/aboutus/AboutHeader";
import OurVision from "../Components/aboutus/OurVision";
import OurValues from "../Components/aboutus/OurValues";
import OurStrategic from "../Components/aboutus/OurStrategic";
import Footer from "../Components/Footer";

const AboutUsPage = () => {
  return (
    <section className="about-us">
      <NavBar />
      <PageHeaderSection header={"About Us"} />
      <AboutHeader />
      <OurVision />
      <OurValues />
      <OurStrategic />
      <Footer />
    </section>
  );
};

export default AboutUsPage;
