import React from 'react';
import classes from './PageHeaderSection.module.css';
const PageHeaderSection = ({ header }) => {
  return (
    <div className={classes.holder}>
      <h1>{header}</h1>
    </div>
  );
};

export default PageHeaderSection;
