import React from "react";
import NavBar from "../Components/NavBar";
import PageHeaderSection from "../UI/PageHeaderSection";
import InteriorPhotos from "../Components/Gallery/InteriorPhotos";
import ExteriorPhotos from "../Components/Gallery/ExteriorPhotos";
import Videos from "../Components/Gallery/Videos";
import Footer from "../Components/Footer";
import Examle from "../Components/Events/Examle";
import CertificatesHolder from "../Components/Cert/CertificatesHolder";

const Certificates = () => {
  return (
    <div className="gallery">
      <NavBar />
      <PageHeaderSection header={"Certificates"} />
      <CertificatesHolder />

      <Footer />
    </div>
  );
};

export default Certificates;
