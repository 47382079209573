import React from "react";
import HeroSection from "../Components/Home/HeroSection";
import AboutUs from "../Components/Home/About-us";
import WhatMake from "../Components/Home/WhatMake";
import Panels from "../Components/Home/Panels";
import OurProducts from "../Components/Home/OurProducts";
import OurProjects from "../Components/Home/OurProjects";
import ContactUs from "../Components/Home/ContactUs";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
const RadioButton = ({ id, label }) => (
  <div className="radio-button">
    <input
      name="radio-group"
      id={id}
      className="radio-button__input"
      type="radio"
    />
    <label htmlFor={id} className="radio-button__label">
      <span className="radio-button__custom"></span>
      {label}
    </label>
  </div>
);
const HomePage = (props) => {
  return (
    <div className="home">
      <NavBar />
      <p className="slogan">Elevating Spaces, Enriching Lives</p>

      <HeroSection />

      <WhatMake />
      <Panels />
      <OurProducts />
      <OurProjects />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default HomePage;
